import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const ChirurgiaPalca = ({ wpChirurgiaPalca }) => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO
        seoTitle="Ortopeda Kraków - dobry lekarz ortopeda - prywatnie"
        description="Nasz gabinet ortopedyczny w to zespół najlepszych specjalistów. Umów się na wizytę u dobrego ortopedy w Krakowie."
      />

      <GlobalTop title="Chirurgia palca" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Czym jest chirurgia palca
                <strong>i jakie zabiegi wykonasz u podologa?</strong>
              </h2>
              <p>
                <span className="purple-text">
                  Obszary aparatu paznokciowego, które wymagają chirurgicznej i
                  bardziej inwazyjnej metody są zaliczane do dziedziny
                  podochirurgii.
                </span>{" "}
                Techniki leczenia zaliczane są do bardzo skutecznych i
                całkowicie eliminują wszystkie dolegliwości. Wzrost płytki
                paznokcia oraz indywidualne możliwości regeneracyjne są różne w
                zależności od osoby. Dlatego też ważne jest stosowanie się do
                zaleceń podologa.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/chirurgia-palca/chirurgia-palca-image-1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/chirurgia-palca/chirurgia-palca-image-2.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Jakie zabiegi wykonasz
                <strong>u podologa?</strong>
              </h2>
              <p>
                <span className="purple-text">
                  Istnieje kilka możliwości operacji, w zależności od
                  dolegliwości pacjenta.
                </span>{" "}
                Wszystko zależy od wstępnej oceny przez podologa, a czasami
                lekarza. Jednak wyróżnia się kilka zabiegów w chirurgii palca.
              </p>
              <p>
                <span className="purple-text">Wrastający paznokieć</span> to
                choroba spowodowana zapalnym przerostem wałów paznokciowych.
                Zazwyczaj do takiej sytuacji dochodzi poprzez błędy w
                pielęgnacji lub urazów. Zabieg pozwala zredukować przerośniętą
                tkankę oraz odseparować płytkę paznokcia, co pozwala na jego
                swobodny prawidłowy wzrost.
              </p>
              <p>
                <span className="purple-text">Plastyka opuszka palca</span>{" "}
                objawia się niepoprawnym torem wzrostu paznokcia oraz
                niewydolnością łożyska paznokciowego. Przekłada się to na
                większą urazowość, odczuwanie bólu a także nieestetyczny wygląd
                paznokcia. Podolog z asystą lekarzy oczyszcza powierzchnię
                dystalnego paliczka, dając tym samym przestrzeń do wzrostu
                paznokcia.
              </p>
              <p>
                <span className="purple-text">Egzostoza podpaznokciowa</span>{" "}
                pojawia się pod postacią łagodnych guzów, które niszczą płytkę
                paznokcia. Zabieg polega na całościowym ich wycięciu i
                przywróceniu prawidłowego funkcjonowania. Leczenie odbywa się w
                taki sposób, aby nie usuwać paznokcia.
              </p>
              <p>
                <span className="purple-text">Retronychia</span> charakteryzuje
                się wrastaniem płytki w macierz. W konsekwencji prowadzi to do
                nawracających bólów i dolegliwości. Podczas zabiegu
                przeprowadzane są działania, które umożliwiają prawidłowe
                przywrócenie wzrostu paznokcia.
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Dla kogo zabiegi chirurgii
                <strong>palca?</strong>
              </h2>
              <p>
                <span className="purple-text">
                  Każda operacja przeprowadzana jest po podaniu znieczulenia
                  miejscowego.
                </span>{" "}
                Pacjent w tym czasie jest przytomny i świadomy, ale nie odczuwa
                bólu. Wykorzystuje się je w medycynie od lat, więc pacjent może
                czuć się bezpiecznie.
              </p>
              <div className="divider" />
              <h2 className="section--header">
                Czy zabiegi są
                <strong>bolesne?</strong>
              </h2>
              <p>
                Przed przystąpieniem do zabiegu pacjent jest badany przez
                podologa. Niektóre schorzenia nie pozwalają na operacje,
                ponieważ może prowadzić to do poważnych powikłań. Nawet jeśli
                pacjent zostanie zdyskwalifikowany, to razem z podologiem mogą
                podjąć inną metodę leczenia dolegliwości.{" "}
                <span className="purple-text">Gabinety Omeda-Med</span> są
                miejscem, gdzie pacjent może przeprowadzić wstępny wywiad pod
                nadzorem{" "}
                <span className="purple-text">
                  mgr Agnieszki Kaszuby – podologa z wieloletnim doświadczeniem.
                </span>
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/chirurgia-palca/chirurgia-palca-image-3.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/chirurgia-palca/chirurgia-palca-image-4.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Rekonwalescencja po zabiegach podochirurgii –
                <strong>ile trwa?</strong>
              </h2>
              <p>
                Zazwyczaj pacjent wraca do aktywności ruchowej po kilkunastu
                dniach. Jednak w tym czasie należy nie przeciążać organizmu, jak
                i leczonego miejsca. Pacjent powinien stawiać się na wizyty w{" "}
                <span className="purple-text">Gabinety Omeda-Med</span>, gdzie
                nad wszystkim czuwa{" "}
                <span className="purple-text">
                  podolog mgr Agnieszka Kaszuba oraz lekarz ortopeda Robert
                  Panikowski.
                </span>
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="offer-single-services">
        <div className="container">
          <h2 className="section--header text-center">Usługi</h2>
          <div className="row">
            {wpChirurgiaPalca.nodes.map((node, index) => (
              <div className="col-xl-4 col-lg-5 col-sm-6" key={index}>
                <Link
                  to={`/oferta/chirurgia-palca${node.uri}`}
                  className="offer-single-services__tile"
                  style={{
                    backgroundImage: `url(${
                      node.acfPodologia.podologiaGridIcon.sourceUrl
                        ? node.acfPodologia.podologiaGridIcon.sourceUrl
                        : ""
                    })`,
                  }}
                >
                  <h4>{node.title}</h4>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        wpChirurgiaPalca: allWpChirurgiaPalca {
          nodes {
            uri
            id
            slug
            title
            acfPodologia {
              podologiaGridIcon {
                sourceUrl
              }
            }
          }
        }
      }
    `}
    render={data => <ChirurgiaPalca {...data} />}
  />
)
